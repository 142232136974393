import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import 'lazysizes';
import './animations.js';
import * as Sentry from "@sentry/browser";

if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.start();

Sentry.init({
    dsn: "https://e0f38d81fa48428054be701007d7e72b@o1102039.ingest.us.sentry.io/4507904526778368",

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});